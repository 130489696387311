.name {
    font-size: 60px;
    font-family: 'Yusei Magic', sans-serif;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .name {
      font-size: 10px;
    }
}
@media (max-width: 350px) {
    /*Possibly someone zoomed in this worse :)*/
    .name {
      font-size: 18px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .name {
      font-size: 30px;
    }
}
@media (min-width: 700px) {
    /* Maybe PC */
    .name {
      font-size: 100px;
    }
}
@media (min-width: 1000px) {
    /* Maybe PC big one*/
    .name {
      font-size: 120px;
    }
}
@media (min-width: 1400px) {
    /* I'm not intersted in more big fonts*/
    .name {
      font-size: 140px;
    }
}