.subtitle{
    font-size: 40px;
}
.details-div{
    padding-top: 1rem;
}
a{
    text-decoration: none;
    color:#81f3fd;
}
.details-div p{
    font-size: 30px;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .subtitle {
      font-size: 10px;
    }
    .details-div p{
        font-size: 8px;
    }
}
@media (max-width: 350px) {
    /*Possibly someone zoomed in this worse :)*/
    .subtitle {
      font-size: 18px;
    }
    .details-div p{
        font-size: 16px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .subtitle {
      font-size: 25px;
    }
    .details-div p{
        font-size: 23px;
    }
}
@media (min-width: 700px) {
    /* Maybe PC */
    .subtitle {
      font-size: 50px;
    }
    .details-div p{
        font-size: 30px;
    }
}
@media (min-width: 1000px) {
    /* Maybe PC big one*/
    .subtitle {
      font-size: 50px;
    }
    .details-div p{
        font-size: 35px;
    }
}
@media (min-width: 1400px) {
    /* I'm not intersted in more big fonts*/
    .subtitle {
      font-size: 40px;
    }
    .details-div p{
        font-size: 35px;
    }
}
