@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
body{
	/*set a gradient background*/
  background: #0F2027;
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
}
.root{
  /* Make things aligned properly */
  display: flex;
  justify-content: center;
  align-items: center;
}

.contents {
  /* neon like colour */
  color:whitesmoke;
  /* set poppings font */
  font-family: 'Poppins', sans-serif;

}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.centered{
  /* Make things aligned properly */
  display: flex;
  justify-content: center;
  align-items: center;
}

.root canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}