@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap);
body{
	/*set a gradient background*/
  background: #0F2027;
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027);
}
.root{
  /* Make things aligned properly */
  display: flex;
  justify-content: center;
  align-items: center;
}

.contents {
  /* neon like colour */
  color:whitesmoke;
  /* set poppings font */
  font-family: 'Poppins', sans-serif;

}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.centered{
  /* Make things aligned properly */
  display: flex;
  justify-content: center;
  align-items: center;
}

.root canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
.name {
    font-size: 60px;
    font-family: 'Yusei Magic', sans-serif;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .name {
      font-size: 10px;
    }
}
@media (max-width: 350px) {
    /*Possibly someone zoomed in this worse :)*/
    .name {
      font-size: 18px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .name {
      font-size: 30px;
    }
}
@media (min-width: 700px) {
    /* Maybe PC */
    .name {
      font-size: 100px;
    }
}
@media (min-width: 1000px) {
    /* Maybe PC big one*/
    .name {
      font-size: 120px;
    }
}
@media (min-width: 1400px) {
    /* I'm not intersted in more big fonts*/
    .name {
      font-size: 140px;
    }
}
.profile-image{
    border-radius: 30px;
    width: 200px;
    height: 200px;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .profile-image {
        border-radius: 10px;
        width: 50px;
        height: 50px;
    }
}
@media (max-width: 300px) {
    /*Possibly someone zoomed in this worse :)*/
    .profile-image {
        border-radius: 20px;
        width: 75px;
        height: 75px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .profile-image {
        border-radius: 25px;
        width: 100px;
        height: 100px;
    }
}

.subtitle{
    font-size: 40px;
}
.details-div{
    padding-top: 1rem;
}
a{
    text-decoration: none;
    color:#81f3fd;
}
.details-div p{
    font-size: 30px;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .subtitle {
      font-size: 10px;
    }
    .details-div p{
        font-size: 8px;
    }
}
@media (max-width: 350px) {
    /*Possibly someone zoomed in this worse :)*/
    .subtitle {
      font-size: 18px;
    }
    .details-div p{
        font-size: 16px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .subtitle {
      font-size: 25px;
    }
    .details-div p{
        font-size: 23px;
    }
}
@media (min-width: 700px) {
    /* Maybe PC */
    .subtitle {
      font-size: 50px;
    }
    .details-div p{
        font-size: 30px;
    }
}
@media (min-width: 1000px) {
    /* Maybe PC big one*/
    .subtitle {
      font-size: 50px;
    }
    .details-div p{
        font-size: 35px;
    }
}
@media (min-width: 1400px) {
    /* I'm not intersted in more big fonts*/
    .subtitle {
      font-size: 40px;
    }
    .details-div p{
        font-size: 35px;
    }
}

