.profile-image{
    border-radius: 30px;
    width: 200px;
    height: 200px;
}
@media (max-width: 200px) {
    /*Possibly someone zoomed in this worse :)*/
    .profile-image {
        border-radius: 10px;
        width: 50px;
        height: 50px;
    }
}
@media (max-width: 300px) {
    /*Possibly someone zoomed in this worse :)*/
    .profile-image {
        border-radius: 20px;
        width: 75px;
        height: 75px;
    }
}
@media (max-width: 400px) {
    /* Maybe mobiles */
    .profile-image {
        border-radius: 25px;
        width: 100px;
        height: 100px;
    }
}
